<template>
  <b-container class="bv-example-row mb-3" fluid>
    <hr/>
    <b-row>
      <b-col xl="4" lg="12" sm="12" cols="12" class="p-0">
        <b-card-actions
            id="cari-info"
            v-if="cariData"
            ref="cari-info"
            @refresh="refreshStop('cari-info')"
            class="mt-1"
        >
          <b-row class="mt-2">
            <b-col cols="12">
              <b-badge variant="light-success">
                {{ $store.getters.GRUP_NAME_GET(cariData.cariGrupID) }}
              </b-badge>
              <b-dropdown
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  variant="flat-dark"
                  no-caret
                  toggle-class="btn-icon"
                  class="float-right"
              >
                <template v-slot:button-content>
                  <font-awesome-icon icon="fa-solid fa-bars"/>
                </template>
                <b-dropdown-item @click="cariToYeniSatis">
                  Satış Faturası Oluştur
                </b-dropdown-item>
                <b-dropdown-item @click="cariToYeniAlis">
                  Alış Faturası Oluştur
                </b-dropdown-item>
                <b-dropdown-item @click="cariToYeniProforma">
                  Proforma Faturası Oluştur
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
          <b-row class="text-center">
            <b-col cols="12">
              <font-awesome-icon icon="fa-solid fa-users" class="customer-icon"/>
              <h4 class="mt-1">
                {{ cariData.firmaUnvan }}
              </h4>
              <b-row>
                <b-col cols="12">
                  <b-badge variant="light-secondary">
                    {{ cariData.adi }} {{ cariData.soyAdi }}
                  </b-badge>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="info-container">
                <ul class="list-unstyled">
                  <li class="mb-75" id="card-btn">
                    <h4 class="d-inline-block font-weight-bold me-25">Kart Detayları</h4>
                    <div class="d-inline-block">
                      <b-button
                          variant="primary"
                          class="btn-icon rounded-circle mr-50"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          size="sm"
                          v-b-modal.cari-not
                          v-b-tooltip.hover.top="'Cari Notu'"
                          :style="{backgroundColor:$store.getters.COLORS_GET.bgPrimary+'!important', border:'none'}"
                      >
                        <font-awesome-icon icon="fa-solid fa-message"/>
                      </b-button>
                      <b-button
                          variant="success"
                          class="btn-icon rounded-circle mr-50"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          size="sm"
                          @click="cariEdit"
                          v-b-modal.cari-edit
                          v-b-tooltip.hover.top="'Cari Kartı Düzenle'"
                          :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important', border:'none'}"
                      >
                        <font-awesome-icon icon="fa-solid fa-user-pen"/>
                      </b-button>
                      <b-button
                          variant="danger"
                          class="btn-icon rounded-circle"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          size="sm"
                          @click="cariRemove"
                          v-b-tooltip.hover.top="'Cari Kartı Sil'"
                          :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important', border:'none'}"
                      >
                        <font-awesome-icon icon="fa-solid fa-trash-can"/>
                      </b-button>
                    </div>
                  </li>
                  <hr/>
                  <li class="mb-75">
                    <span class="font-weight-bold me-25">Vergi Numarası : </span>
                    <span>{{ cariData.vergiNumarasi }}</span>
                  </li>
                  <li class="mb-75">
                    <span class="font-weight-bold me-25">Vergi Dairesi : </span>
                    <span>{{ cariData.vergiDairesi }}</span>
                  </li>
                  <li class="mb-75">
                    <span class="font-weight-bold me-25">Telefon : </span>
                    <span>{{ cariData.telefon }}</span>
                  </li>
                  <li class="mb-75">
                    <span class="font-weight-bold me-25">E-Posta : </span>
                    <span>{{ cariData.ePosta }}</span>
                  </li>
                  <li class="mb-75">
                    <span class="font-weight-bold me-25">Banka : </span>
                    <span>{{ cariData.banka }}</span>
                  </li>
                  <li class="mb-75">
                    <span class="font-weight-bold me-25">İban Adresi : </span>
                    <span>{{ cariData.ibann }}</span>
                  </li>
                  <li class="mb-75">
                    <span class="font-weight-bold me-25">Adres : </span>
                    <span>{{ cariData.adres }}</span>
                  </li>
                  <li class="mb-75">
                    <span class="font-weight-bold me-25">İl : </span>
                    <span>{{ cariData.il }}</span>
                  </li>
                  <li class="mb-75">
                    <span class="font-weight-bold me-25">İlçe : </span>
                    <span>{{ cariData.ilce }}</span>
                  </li>
                </ul>
                <hr/>
                <b-row>
                  <b-col class="media-responsive">
                    <b-media no-body>
                      <b-media-aside
                          class="media-aside-p"
                      >
                        <b-avatar
                            size="48"
                            variant="light-primary"
                            class="pull-up"
                        >
                          <font-awesome-icon icon="fa-solid fa-receipt" class="fa-2xl"/>
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0 cari-ozet-money">
                          {{
                            Number(cariOzet.alisFaturalar)
                          }} <font-awesome-icon icon="turkish-lira-sign"/>
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          Alış Faturaları
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  <b-col class="media-responsive">
                    <b-media no-body>
                      <b-media-aside
                          class="media-aside-p"
                      >
                        <b-avatar
                            size="48"
                            variant="light-danger"
                            class="pull-up"
                        >
                          <font-awesome-icon icon="fa-solid fa-receipt" class="fa-2xl"/>
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0 cari-ozet-money">
                          {{
                            Number(cariOzet.satisFaturalar)
                          }} <font-awesome-icon icon="turkish-lira-sign"/>
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          Satış Faturaları
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  <b-col class="media-responsive">
                    <b-media no-body id="cari-media">
                      <b-media-aside
                          class="media-aside-p"
                      >
                        <b-avatar
                            size="48"
                            :variant="cariOzet.satislar - cariOzet.alislar < 0 ? 'light-warning':'light-success'"
                            class="pull-up"
                        >
                          <font-awesome-icon icon="fa-solid fa-turkish-lira-sign" class="fa-2xl"/>
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0 cari-ozet-money">
                          {{
                            Number(cariOzet.bakiye)
                          }} <font-awesome-icon icon="turkish-lira-sign"/>
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          Bakiye
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
      <b-col xl="8" lg="12" sm="12" cols="12">
        <b-card-actions
            id="cari-tab"
            ref="cari-tab"
            @refresh="refreshStop('cari-tab')"
            class="mt-1"
        >
          <b-row class="mt-1 ml-25">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mr-50"
                v-b-modal.tahsilat-girisi
                :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important', border:'none'}"
            >
              <font-awesome-icon icon="fa-solid fa-plus"/>
              Tahsilat Girişi
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="mr-50"
                v-b-modal.borc-girisi
                :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important', border:'none'}"
                :class="width < 400 ? 'mt-50':''"
            >
              <font-awesome-icon icon="fa-solid fa-minus"/>
              Ödeme Yap
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                class="mr-50"
                v-b-modal.devir-girisi
                :style="{backgroundColor:$store.getters.COLORS_GET.cardTitleButton+'!important', border:'none'}"
                :class="width < 545 ? 'mt-50':''"
            >
              <font-awesome-icon icon="fa-solid fa-file-export"/>
              Devir Girişi
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                class="mr-50"
                @click="cariEkstrePush"
                :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important', border:'none'}"
                :class="width < 713 ? 'mt-50':''"
            >
              <font-awesome-icon icon="fa-solid fa-file-export"/>
              Cari Ekstre
            </b-button>
          </b-row>
          <hr/>
          <b-tabs class="mt-1">
            <b-tab>
              <template #title>
                <font-awesome-icon icon="fa-solid fa-clock-rotate-left"/>
                <span>İşlem Geçmişi</span>
              </template>
              <div class="d-flex justify-content-between flex-wrap mt-2">
                <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
                  <b-input-group
                      size="sm"
                      prepend="Gösterilecek kayıt sayısı"
                      class="unPrint"
                  >
                    <b-form-select
                        id="per-page-select"
                        v-model="igPerPage"
                        :options="perPageOptions"
                        @change="igPerPageChange($event)"
                        size="sm"
                    >
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
                <b-input-group size="sm" class="search-bar" :style="{width:width < 1000 ?'40%!important':''}">
                  <b-input-group-prepend>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        disabled
                        class="search-input mb-0"
                        :style="{height:width < 768 ?'30px':''}"
                    >
                      Ara
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input id="filterInput" v-model="igFilter" type="search"/>
                </b-input-group>
              </div>
              <b-table
                  striped
                  hover
                  responsive
                  class="position-relative cari-group-table mt-1 islem-gecmisi"
                  :per-page="igPerPage"
                  :items="hesapOzeti"
                  :fields="igFields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter-included-fields="igFilterOn"
                  @filtered="onFiltered"
              >
                <template #cell(hareket)="data">
                  <b-badge
                      v-if="data.item.fatura != 1"
                      variant="danger"
                      v-b-tooltip.hover.right="'İşlemi Sil'"
                      @click="tahsilatDelete(data.item.kasaID)"
                      class="mr-50"
                  >
                    <font-awesome-icon icon="fa-solid fa-xmark"/>
                  </b-badge>
                  <b-badge
                      :variant="data.item.alacak ?'success':'primary'" id="firma-unvan-badge"
                      class="align-top"
                  >
                    <font-awesome-icon icon="fa-solid fa-hashtag"/>
                    {{ data.item.alacak ? 'Tahsilat' : 'Ödeme' }}
                  </b-badge>
                </template>
                <template #cell(kayitTarih)="data">
                  <font-awesome-icon icon="fa-solid fa-calendar-days"/>
                  <p class="d-inline-block ml-50">
                    {{ data.item.kayitTarih.slice(0, 16) }}
                  </p>
                </template>
                <template #cell(islemNotu)="data">
                  <font-awesome-icon v-if="data.item.islemNotu" icon="fa-solid fa-message"/>
                  <strong class="ml-50">
                    {{ data.item.islemNotu }}
                  </strong>
                </template>
                <template #cell(alacak)="data">
                  <strong class="ml-50">
                    {{ data.item.alacak || data.item.borc }} TL
                  </strong>
                  <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                </template>
                <template #cell(bakiye)="data">
                  <strong>
                    {{ data.item.bakiye }}
                  </strong>
                  <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                  <small
                      :class="{'text-success' :data.item.bakiye > 0  , 'text-danger' : data.item.bakiye < 0 , 'text-muted':data.item.bakiye== 0 }"
                      class="d-block"
                  >
                    {{
                      data.item.bakiye > 0 ? 'Tahsil Edilecek´' : data.item.bakiye < 0 ? 'Ödenecek' : data.item.bakiye == 0 ? 'Bakiye Yok' : ''
                    }}
                  </small>
                </template>
              </b-table>
              <hr/>
              <b-row class="d-flex align-items-center mt-1">
                <b-col cols="6">
                  <p class="text-muted">Toplam {{ igToplamKayit }} Kayıt bulundu</p>
                </b-col>
                <b-col cols="6">
                  <b-pagination
                      v-model="igCurrentPage"
                      @change="igPageChange($event)"
                      :total-rows="igToplamKayit"
                      :per-page="igPerPage"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mb-0 float-right"
                  />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab @click="fatura(true)">
              <template #title>
                <font-awesome-icon icon="fa-regular fa-file-lines"/>
                <span>Açık Faturalar</span>
              </template>
              <div class="d-flex justify-content-between flex-wrap mt-2">
                <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
                  <b-input-group
                      size="sm"
                      prepend="Gösterilecek kayıt sayısı"
                      class="unPrint"
                  >
                    <b-form-select
                        id="per-page-select"
                        v-model="afPerPage"
                        :options="perPageOptions"
                        @change="afPerPageChange($event)"
                        size="sm"
                    >
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
                <b-input-group size="sm" class="search-bar" :style="{width:width < 1000 ?'40%!important':''}">
                  <b-input-group-prepend>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        disabled
                        class="search-input mb-0"
                        :style="{height:width < 768 ?'30px':''}"
                    >
                      Ara
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input id="filterInput" v-model="afFilter" type="search"/>
                </b-input-group>
              </div>
              <b-table
                  striped
                  hover
                  responsive
                  class="position-relative cari-group-table mt-1 tahsilat-ve-odeme-kayitlari"
                  :per-page="afPerPage"
                  :items="acikFaturalar"
                  :fields="fFields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter-included-fields="afFilterOn"
                  @filtered="onFiltered"
                  @row-clicked="goFatura"
              >
                <template #cell(faturaNo)="data">
                  <b-badge
                      variant="primary"
                      class="badge-glow"
                      v-if="data.item.faturaNo"
                  >
                    #{{ data.item.faturaNo }}
                  </b-badge>
                </template>
                <template #cell(faturaTuru)="data">
                  <b-badge
                      :variant="data.item.faturaTuru == 1 ?'success':'warning'"
                      class="badge-glow"
                  >
                    {{ data.item.faturaTuru == 1 ? 'Satış Faturası' : 'Alış Faturası' }}
                  </b-badge>
                </template>
                <template #cell(faturaNotu)="data">
                  <p class="d-inline-block mr-1">
                    {{ data.item.faturaNotu }}
                  </p>
                </template>
                <template #cell(kayitTarih)="data">
                  <font-awesome-icon icon="fa-solid fa-calendar-days"/>
                  <strong class="ml-50">
                    {{ data.item.kayitTarih.slice(0, 16) }}
                  </strong>
                </template>
                <template #cell(toplamTutar)="data">
                  <strong class="ml-50">
                    {{
                      Number(data.item.toplamTutar)
                    }}
                    <font-awesome-icon icon="turkish-lira-sign"/>
                  </strong>
                </template>
              </b-table>
              <b-row class="d-flex align-items-center mt-1">
                <b-col cols="6">
                  <p class="text-muted">Toplam {{ afToplamKayit }} Kayıt bulundu</p>
                </b-col>
                <b-col cols="6">
                  <b-pagination
                      v-model="afCurrentPage"
                      @change="afPageChange($event)"
                      :total-rows="afToplamKayit"
                      :per-page="afPerPage"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mb-0 float-right"
                  />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab @click="fatura(false)">
              <template #title>
                <font-awesome-icon icon="fa-solid fa-receipt"/>
                <span>Kapalı Faturalar</span>
              </template>
              <div class="d-flex justify-content-between flex-wrap mt-2">
                <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
                  <b-input-group
                      size="sm"
                      prepend="Gösterilecek kayıt sayısı"
                      class="unPrint"
                  >
                    <b-form-select
                        id="per-page-select"
                        v-model="fPerPage"
                        :options="perPageOptions"
                        @change="fPerPageChange($event)"
                        size="sm"
                    >
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
                <b-input-group size="sm" class="search-bar" :style="{width:width < 1000 ?'40%!important':''}">
                  <b-input-group-prepend>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        disabled
                        class="search-input mb-0"
                        :style="{height:width < 768 ?'30px':''}"
                    >
                      Ara
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input id="filterInput" v-model="fFilter" type="search"/>
                </b-input-group>
              </div>
              <b-table
                  striped
                  hover
                  responsive
                  class="position-relative cari-group-table mt-1 tahsilat-ve-odeme-kayitlari"
                  :per-page="fPerPage"
                  :items="faturalar"
                  :fields="fFields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter-included-fields="fFilterOn"
                  @filtered="onFiltered"
                  @row-clicked="goFatura"
              >
                <template #cell(faturaNo)="data">
                  <b-badge
                      variant="primary"
                      class="badge-glow"
                      v-if="data.item.faturaNo"
                  >
                    #{{ data.item.faturaNo }}
                  </b-badge>
                </template>
                <template #cell(faturaTuru)="data">
                  <b-badge
                      :variant="data.item.faturaTuru == 1 ?'success':'warning'"
                      class="badge-glow"
                  >
                    {{ data.item.faturaTuru == 1 ? 'Satış Faturası' : 'Alış Faturası' }}
                  </b-badge>
                </template>
                <template #cell(faturaNotu)="data">
                  <p class="d-inline-block mr-1">
                    {{ data.item.faturaNotu }}
                  </p>
                </template>
                <template #cell(kayitTarih)="data">
                  <font-awesome-icon icon="fa-solid fa-calendar-days"/>
                  <strong class="ml-50">
                    {{ data.item.kayitTarih.slice(0, 16) }}
                  </strong>
                </template>
                <template #cell(toplamTutar)="data">
                  <strong class="ml-50">
                    {{
                      Number(data.item.toplamTutar)
                    }}
                    <font-awesome-icon icon="turkish-lira-sign"/>
                  </strong>
                </template>
              </b-table>
              <b-row class="d-flex align-items-center mt-1">
                <b-col cols="6">
                  <p class="text-muted">Toplam {{ fToplamKayit }} Kayıt bulundu</p>
                </b-col>
                <b-col cols="6">
                  <b-pagination
                      v-model="fCurrentPage"
                      @change="fPageChange($event)"
                      :total-rows="fToplamKayit"
                      :per-page="fPerPage"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mb-0 float-right"
                  />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab @click="tahsilatlar">
              <template #title>
                <font-awesome-icon icon="fa-solid fa-box-archive"/>
                <span>Tahsilat ve Ödeme Kayıtları</span>
              </template>
              <div class="d-flex justify-content-between flex-wrap mt-2">
                <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
                  <b-input-group
                      size="sm"
                      prepend="Gösterilecek kayıt sayısı"
                      class="unPrint"
                  >
                    <b-form-select
                        id="per-page-select"
                        v-model="tPerPage"
                        :options="perPageOptions"
                        @change="tPerPageChange($event)"
                        size="sm"
                    >
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
                <b-input-group size="sm" class="search-bar" :style="{width:width < 1000 ?'40%!important':''}">
                  <b-input-group-prepend>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        disabled
                        class="search-input mb-0"
                        :style="{height:width < 768 ?'30px':''}"
                    >
                      Ara
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input id="filterInput" v-model="tFilter" type="search"/>
                </b-input-group>
              </div>
              <b-table
                  striped
                  hover
                  responsive
                  class="position-relative cari-group-table mt-1 tahsilat-ve-odeme"
                  :per-page="tPerPage"
                  :items="odemeKayitlari"
                  :fields="tFields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter-included-fields="tFilterOn"
                  @filtered="onFiltered"
              >
                <template #cell(hareket)="data">
                  <b-badge
                      :variant="data.item.hareket == 1 ?'success':'primary'" id="firma-unvan-badge"
                      class="align-top mr-50"
                  >
                    <font-awesome-icon icon="fa-solid fa-hashtag"/>
                    {{ data.item.hareket == 1 ? 'Tahsilat' : 'Ödeme' }}
                  </b-badge>
                </template>
                <template #cell(islemNotu)="data">
                  <font-awesome-icon v-if="data.item.islemNotu" icon="fa-solid fa-message"/>
                  <strong class="ml-50">
                    {{ data.item.islemNotu }}
                  </strong>
                </template>
                <template #cell(kayitTarih)="data">
                  <font-awesome-icon icon="fa-solid fa-calendar-days"/>
                  <p class="d-inline-block ml-50">
                    {{ data.item.kayitTarih.slice(0, 16) }}
                  </p>
                </template>
                <template #cell(tutar)="data">
                  <strong class="ml-50">
                    {{ data.item.tutar }}
                  </strong>
                  <font-awesome-icon v-if="data.item.tutar" icon="fa-solid fa-turkish-lira-sign"/>
                </template>
              </b-table>
              <b-row class="d-flex align-items-center mt-1">
                <b-col cols="6">
                  <p class="text-muted">Toplam {{ tToplamKayit }} Kayıt bulundu</p>
                </b-col>
                <b-col cols="6">
                  <b-pagination
                      v-model="tCurrentPage"
                      @change="tPageChange($event)"
                      :total-rows="tToplamKayit"
                      :per-page="tPerPage"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mb-0 float-right"
                  />
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-modal
        id="borc-girisi"
        title="Hızlı Ödeme"
        hide-footer
        @hidden="odemeReset"
    >
      <b-tabs content-class="pt-1" fill>
        <b-tab
            title="Standart"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group
                  label="Ödeme Şekli:"
              >
                <model-list-select
                    :list="sOdeme.tahsilatSekli"
                    v-model="sOdeme.tahsilat"
                    option-value="key"
                    option-text="title"
                >
                </model-list-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Ödenen Tutar:"
                  label-for="basicInput"
              >
                <b-form-input
                    autocomplete="off"
                    id="basicInput"
                    v-model="sOdeme.tutar"
                    :step="priceStep"
                    type="number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Fiş No:"
                  label-for="basicInput"
              >
                <b-form-input
                    autocomplete="off"
                    id="basicInput"
                    v-model="sOdeme.fisNo"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="İşlem Notu:"
                  label-for="basicInput"
              >
                <b-form-textarea
                    id="textarea-default"
                    rows="3"
                    v-model="sOdeme.islemNotu"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="d-flex justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="secondary"
                  class="mr-50"
                  @click="odemeReset"
              >
                <span class="align-middle">Kapat</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  @click="kasaGirisi('standart','cari-tab',2)"
                  :style="{backgroundColor:$store.getters.COLORS_GET.bgPrimary+'!important', border:'none'}"
              >
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                <span class="align-middle">Ödeme Yap</span>
              </b-button>
            </b-col>
          </b-row>
        </b-tab>
        <!--        <b-tab-->
        <!--          title="Çek İle Tahsilat"-->
        <!--        >-->
        <!--          <b-row>-->
        <!--            <b-col cols="12">-->
        <!--              <b-form-group-->
        <!--                label="Çek Üzerindeki İsim:"-->
        <!--              >-->
        <!--                <b-form-input-->
        <!--                  autocomplete="off"-->
        <!--                  id="basicInput"-->
        <!--                  v-model="cekOdeme.isim"-->
        <!--                />-->
        <!--              </b-form-group>-->
        <!--            </b-col>-->
        <!--            <b-col cols="12">-->
        <!--              <b-form-group-->
        <!--                label="Seri No:"-->
        <!--              >-->
        <!--                <b-form-input-->
        <!--                  autocomplete="off"-->
        <!--                  id="basicInput"-->
        <!--                  v-model="cekOdeme.seriNo"-->
        <!--                />-->
        <!--              </b-form-group>-->
        <!--            </b-col>-->
        <!--            <b-col cols="12">-->
        <!--              <b-form-group>-->
        <!--                <h6 class="font-weight-normal">Düzenlenme Tarihi:</h6>-->
        <!--                <flat-pickr-->
        <!--                  v-model="cekOdeme.duzenlenmeTarihi"-->
        <!--                  class="form-control"-->
        <!--                />-->
        <!--              </b-form-group>-->
        <!--            </b-col>-->
        <!--            <b-col cols="12">-->
        <!--              <b-form-group>-->
        <!--                <h6 class="font-weight-normal">Tahsilat Tarihi:</h6>-->
        <!--                <flat-pickr-->
        <!--                  v-model="cekOdeme.tahsilatTarihi"-->
        <!--                  class="form-control"-->
        <!--                />-->
        <!--              </b-form-group>-->
        <!--            </b-col>-->
        <!--            <b-col cols="12">-->
        <!--              <b-form-group>-->
        <!--                <h6 class="font-weight-normal">Tutar:</h6>-->
        <!--                <b-form-input-->
        <!--                  autocomplete="off"-->
        <!--                  :step="priceStep"-->
        <!--                  v-model="cekOdeme.tutar"-->
        <!--                  class="form-control"-->
        <!--                  type="number"-->
        <!--                />-->
        <!--              </b-form-group>-->
        <!--            </b-col>-->
        <!--            <b-col cols="12">-->
        <!--              <b-form-group-->
        <!--                label="İşlem Notu:"-->
        <!--                label-for="basicInput"-->
        <!--              >-->
        <!--                <b-form-textarea-->
        <!--                  id="textarea-default"-->
        <!--                  autocomplete="off"-->
        <!--                  class="form-control"-->
        <!--                  rows="3"-->
        <!--                  v-model="cekOdeme.islemNotu"-->
        <!--                />-->
        <!--              </b-form-group>-->
        <!--            </b-col>-->
        <!--            <b-col cols="12" class="d-flex justify-content-end">-->
        <!--              <b-button-->
        <!--                v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
        <!--                variant="secondary"-->
        <!--                class="mr-50"-->
        <!--                @click="odemeReset"-->
        <!--              >-->
        <!--                <span class="align-middle">Kapat</span>-->
        <!--              </b-button>-->
        <!--              <b-button-->
        <!--                v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
        <!--                variant="success"-->
        <!--                @click="kasaGirisi('cek','cari-tab',2)"-->
        <!--                :style="{backgroundColor:$store.getters.COLORS_GET.bgPrimary+'!important', border:'none'}"-->
        <!--              >-->
        <!--                <feather-icon-->
        <!--                  icon="SaveIcon"-->
        <!--                  class="mr-50"-->
        <!--                />-->
        <!--                <span class="align-middle">Ödeme Yap</span>-->
        <!--              </b-button>-->
        <!--            </b-col>-->
        <!--          </b-row>-->
        <!--        </b-tab>-->
      </b-tabs>
    </b-modal>
    <b-modal
        id="tahsilat-girisi"
        title="Hızlı Tahsilat"
        hide-footer
        @hidden="tahsilatReset"
    >
      <b-tabs content-class="pt-1" fill>
        <b-tab
            title="Standart"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group
                  label="Tahsilat Şekli:"
              >
                <model-list-select
                    :list="sTahsilat.tahsilatSekli"
                    v-model="sTahsilat.tahsilat"
                    option-value="key"
                    option-text="title"
                >
                </model-list-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Tutar:"
                  label-for="basicInput"
              >
                <b-form-input
                    autocomplete="off"
                    id="basicInput"
                    v-model="sTahsilat.tutar"
                    :step="priceStep"
                    type="number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Fiş No:"
                  label-for="basicInput"
              >
                <b-form-input
                    autocomplete="off"
                    id="basicInput"
                    v-model="sTahsilat.fisNo"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="İşlem Notu:"
                  label-for="basicInput"
              >
                <b-form-textarea
                    id="textarea-default"
                    rows="3"
                    v-model="sTahsilat.islemNotu"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="d-flex justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="secondary"
                  class="mr-50"
                  @click="tahsilatReset"
              >
                <span class="align-middle">Kapat</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="success"
                  @click="kasaGirisi('standart','cari-tab',1)"
                  :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important', border:'none'}"
              >
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                <span class="align-middle">Tahsilat Yap</span>
              </b-button>
            </b-col>
          </b-row>
        </b-tab>
        <!--        <b-tab-->
        <!--          title="Çek İle Tahsilat"-->
        <!--        >-->
        <!--          <b-row>-->
        <!--            <b-col cols="12">-->
        <!--              <b-form-group-->
        <!--                label="Çek Üzerindeki İsim:"-->
        <!--              >-->
        <!--                <b-form-input-->
        <!--                  autocomplete="off"-->
        <!--                  id="basicInput"-->
        <!--                  v-model="cekTahsilat.isim"-->
        <!--                />-->
        <!--              </b-form-group>-->
        <!--            </b-col>-->
        <!--            <b-col cols="12">-->
        <!--              <b-form-group-->
        <!--                label="Seri No:"-->
        <!--              >-->
        <!--                <b-form-input-->
        <!--                  autocomplete="off"-->
        <!--                  id="basicInput"-->
        <!--                  v-model="cekTahsilat.seriNo"-->
        <!--                />-->
        <!--              </b-form-group>-->
        <!--            </b-col>-->
        <!--            <b-col cols="12">-->
        <!--              <b-form-group>-->
        <!--                <h6 class="font-weight-normal">Düzenlenme Tarihi:</h6>-->
        <!--                <flat-pickr-->
        <!--                  v-model="cekTahsilat.duzenlenmeTarihi"-->
        <!--                  class="form-control"-->
        <!--                />-->
        <!--              </b-form-group>-->
        <!--            </b-col>-->
        <!--            <b-col cols="12">-->
        <!--              <b-form-group>-->
        <!--                <h6 class="font-weight-normal">Tahsilat Tarihi:</h6>-->
        <!--                <flat-pickr-->
        <!--                  v-model="cekTahsilat.tahsilatTarihi"-->
        <!--                  class="form-control"-->
        <!--                />-->
        <!--              </b-form-group>-->
        <!--            </b-col>-->
        <!--            <b-col cols="12">-->
        <!--              <b-form-group>-->
        <!--                <h6 class="font-weight-normal">Tutar:</h6>-->
        <!--                <b-form-input-->
        <!--                  autocomplete="off"-->
        <!--                  :step="priceStep"-->
        <!--                  v-model="cekTahsilat.tutar"-->
        <!--                  class="form-control"-->
        <!--                  type="number"-->
        <!--                />-->
        <!--              </b-form-group>-->
        <!--            </b-col>-->
        <!--            <b-col cols="12">-->
        <!--              <b-form-group-->
        <!--                label="İşlem Notu:"-->
        <!--                label-for="basicInput"-->
        <!--              >-->
        <!--                <b-form-textarea-->
        <!--                  id="textarea-default"-->
        <!--                  autocomplete="off"-->
        <!--                  class="form-control"-->
        <!--                  rows="3"-->
        <!--                  v-model="cekTahsilat.islemNotu"-->
        <!--                />-->
        <!--              </b-form-group>-->
        <!--            </b-col>-->
        <!--            <b-col cols="12" class="d-flex justify-content-end">-->
        <!--              <b-button-->
        <!--                v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
        <!--                variant="secondary"-->
        <!--                class="mr-50"-->
        <!--                @click="tahsilatReset"-->
        <!--              >-->
        <!--                <span class="align-middle">Kapat</span>-->
        <!--              </b-button>-->
        <!--              <b-button-->
        <!--                v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
        <!--                variant="success"-->
        <!--                @click="kasaGirisi('cek','cari-tab',1)"-->
        <!--                :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important', border:'none'}"-->
        <!--              >-->
        <!--                <feather-icon-->
        <!--                  icon="SaveIcon"-->
        <!--                  class="mr-50"-->
        <!--                />-->
        <!--                <span class="align-middle">Tahsilat Yap</span>-->
        <!--              </b-button>-->
        <!--            </b-col>-->
        <!--          </b-row>-->
        <!--        </b-tab>-->
      </b-tabs>
    </b-modal>
    <b-modal
        id="cari-not"
        centered
        title="Cari Notu"
        ok-only
        ok-title="Kaydet"
        @ok="noteUpdate"
    >
      <b-form-textarea rows="5" v-model="cariNote"/>
    </b-modal>
    <b-modal
        id="devir-girisi"
        centered
        title="Devir Girişi"
        ok-only
        hide-footer
        @ok="noteUpdate"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Devir Hareketi:"
          >
            <model-list-select
                :list="devir.hareketSekli"
                v-model="devir.hareket"
                option-value="key"
                option-text="title"
            >
            </model-list-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Devir Tutarı:"
              label-for="basicInput"
          >
            <b-form-input
                autocomplete="off"
                id="basicInput"
                v-model="devir.tutar"
                :step="priceStep"
                type="number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="İşlem Notu:"
              label-for="basicInput"
          >
            <b-form-textarea
                id="textarea-default"
                rows="3"
                v-model="devir.islemNotu"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="secondary"
              class="mr-50"
              @click="devirReset"
          >
            <span class="align-middle">Kapat</span>
          </b-button>
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="success"
              @click="devirGirisi"
              :style="{backgroundColor:$store.getters.COLORS_GET.cardTitleButton+'!important', border:'none'}"
          >
            <feather-icon
                icon="SaveIcon"
                class="mr-50"
            />
            <span class="align-middle">Kaydet</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="cari-edit"
        ok-only
        hide-footer
        centered
        size="lg"
        header-bg-variant="white"
        @hidden="editHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <h3>
              <b-badge variant="primary" class="modalBadge">
                Cari Bilgileri
              </b-badge>
            </h3>
          </b-col>
          <b-col cols="6" class="mt-1">
            <b-form-group label="İsim" label-for="basicInput">
              <b-form-input autocomplete="off" v-model="editCari.adi" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="6" class="mt-1">
            <b-form-group label="Soyisim" label-for="soy-isim">
              <b-form-input autocomplete="off" v-model="editCari.soyAdi" id="soy-isim"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Cari Grup" label-for="basicInput">
              <model-list-select
                  class=""
                  v-model="editCari.cariGrupID"
                  :list="groupGet"
                  option-value="cariGrupID"
                  option-text="grupAdi"

                  placeholder="Cari Grup Seçiniz"
              >
              </model-list-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="E-Posta" label-for="basicInput">
              <b-form-input autocomplete="off" v-model="editCari.ePosta" id="e-posta"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Telefon" label-for="basicInput">
              <b-form-input autocomplete="off" v-model="editCari.telefon" id="telefon"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="İl"
                label-for="basicInput"
            >
              <model-list-select
                  class=""
                  :list="json"
                  v-model="editCari.il"
                  option-value="il_adi"
                  option-text="il_adi"

                  name="unvan"
                  placeholder="İl Seçiniz"
              >
              </model-list-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="İlçe"
            >
              <model-list-select
                  class=""
                  :list="ilceler"
                  v-model="editCari.ilce"
                  option-value="ilce_adi"
                  option-text="ilce_adi"

                  placeholder="İlçe Seçiniz"
              >
              </model-list-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Adres" label-for="basicInput">
              <b-form-input autocomplete="off" v-model="editCari.adres" id="adres"/>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Cari Not" label-for="basicInput">
              <b-form-input autocomplete="off" v-model="editCari.cariNot" id="cari-not"/>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h3>
              <b-badge variant="success" class="modalBadge">
                Fatura Bilgileri
              </b-badge>
            </h3>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Firma Ünvanı"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input autocomplete="off" v-model="editCari.firmaUnvan" id="firma-unvan"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Vergi No / TC NO"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input autocomplete="off" v-model="editCari.vergiNumarasi" id="vergiNo"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Banka"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input autocomplete="off" v-model="editCari.banka" id="banka"/>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="İBAN"
                label-for="basicInput"
                class="mt-1"
            >
              <b-input-group @paste="ibanPaste">
                <b-input-group-prepend is-text>
                  TR
                </b-input-group-prepend>
                <cleave
                    v-model="editCari.ibann"
                    class="form-control"
                    :options="options.block"
                    placeholder="00 0000 0000 0000 0000 0000 00"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Vergi Dairesi"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input autocomplete="off" v-model="editCari.vergiDairesi" id="vergi-dairesi"/>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="bgInfo"
                @click="editCari.adi && editCari.soyAdi && editCari.cariGrupID && (editCari.vergiNumarasi.length == 10 || editCari.vergiNumarasi.length == 11) ? cariUpdate() : false"
                :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none',cursor: !editCari.adi || !editCari.soyAdi || !editCari.cariGrupID || !(editCari.vergiNumarasi.length == 10 || editCari.vergiNumarasi.length == 11) ? 'default':'pointer',opacity: !editCari.adi || !editCari.soyAdi || !editCari.cariGrupID || !(editCari.vergiNumarasi.length == 10 || editCari.vergiNumarasi.length == 11) ? 0.5: 1}"
                v-b-tooltip="!editCari.adi ? 'Cari Adı Zorunludur.' :  !editCari.soyAdi  ?'Cari Soyismi Zorunludur.' : !editCari.cariGrupID ? 'Cari Grubu Zorunludur.': !(editCari.vergiNumarasi.length == 10 || editCari.vergiNumarasi.length == 11) ? 'Vergi numarası 10 haneli veya TC Kimlik numarası 11 haneli olmalıdır.':'' "
            >
              Güncelle
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BBreadcrumb,
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BMediaBody,
  BModal,
  BPagination,
  BRow,
  BSpinner,
  BTab,
  BTable,
  BTabs,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'
import _ from 'lodash'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import updateModal from './cariUpdateModal.vue'
import axios from 'axios'
import flatPickr from 'vue-flatpickr-component'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)
import { useWindowSize } from '@vueuse/core'
import Cleave from 'vue-cleave-component'

export default {
  setup() {
    const json = require('@/assets/turkey-cities.json').data
    const ilceler = []
    const {
      width,
    } = useWindowSize()
    return {
      json,
      ilceler,
      width,
    }
  },
  components: {
    Cleave,
    BDropdown,
    BDropdownItem,
    flatPickr,
    BTab,
    BTabs,
    BCardText,
    BImg,
    updateModal,
    ModelListSelect,
    BInputGroupPrepend,
    BCardActions,
    BCardHeader,
    BCardBody,
    BFormSelectOption,
    AppTimeline,
    AppTimelineItem,
    BFormTextarea,
    vSelect,
    BMedia,
    BMediaAside,
    BMediaBody,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BInputGroup,
    BSpinner,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BFormCheckboxGroup,
    BFormCheckbox,
    BModal,
  },
  data() {
    return {
      options: {
        block: {
          blocks: [2, 4, 4, 4, 4, 4, 2],
          uppercase: true,
        },
      },
      devir: {
        islemNotu: '',
        tutar: '',
        hareket: {
          title: 'Cari Borcu',
          key: 2,
        },
        hareketSekli: [
          {
            title: 'Cari Borcu',
            key: 2,
          },
          {
            title: 'Cari Alacağı',
            key: 1,
          },
        ],
      },
      isAcikFatura: false,
      trashIconAnimation: [],
      cariNote: '',
      editCari: {
        cariGrupID: '',
        cariCardID: '',
        adi: '',
        soyAdi: '',
        adres: '',
        ePosta: '',
        telefon: '',
        firmaUnvan: '',
        vergiNumarasi: '',
        vergiDairesi: '',
        il: '',
        ilce: '',
        bakiye: '',
        cariNot: '',
        banka: '',
        ibann: '',
      },
      degismeyenData: [],
      sTahsilat: {
        islemNotu: '',
        tutar: '',
        fisNo: '',
        tahsilat: {
          title: 'Nakit',
          key: 1,
        },
        tahsilatSekli: [
          {
            title: 'Nakit',
            key: 1,
          },
          {
            title: 'Kredi Kartı',
            key: 2,
          },
          {
            title: 'Havale',
            key: 3,
          },
        ],
      },
      cekTahsilat: {
        islemNotu: '',
        tutar: '',
        seriNo: '',
        duzenlenmeTarihi: '',
        tahsilatTarihi: '',
        isim: '',
      },
      sOdeme: {
        islemNotu: '',
        tutar: '',
        fisNo: '',
        tahsilat: {
          title: 'Nakit',
          key: 1,
        },
        tahsilatSekli: [
          {
            title: 'Nakit',
            key: 1,
          },
          {
            title: 'Kredi Kartı',
            key: 2,
          },
          {
            title: 'Havale',
            key: 3,
          },
        ],
      },
      cekOdeme: {
        islemNotu: '',
        tutar: '',
        seriNo: '',
        duzenlenmeTarihi: '',
        tahsilatTarihi: '',
        isim: '',
      },
      windowWidth: 0,
      windowHeight: 0,
      id: this.$route.params.id,
      // islem gecmisi
      sortBy: '',
      igFields: [
        {
          key: 'hareket',
          label: 'İşlem Türü',
          sortable: true,
          thStyle: {
            width: '15%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'islemNotu',
          label: 'Açıklama',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'kayitTarih',
          label: 'İşlem Tarihi',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'alacak',
          label: 'Meblağ',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'bakiye',
          label: 'Bakiye',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
      ],
      sortDirection: 'asc',
      sortDesc: false,
      perPageOptions: [5, 10, 15, 20, 25, 50, 100],
      igPerPage: 10,
      igCurrentPage: 1,
      igToplamKayit: 0,
      igMevcutKayit: 0,
      igFilter: '',
      igFilterOn: [],
      // faturalar
      fPerPage: 10,
      fToplamKayit: 0,
      fMevcutKayit: 0,
      fFilter: '',
      fCurrentPage: 1,
      fFilterOn: [],
      fFields: [
        {
          key: 'faturaNo',
          label: 'Fatura No',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'faturaTuru',
          label: 'Fatura Türü',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'faturaNotu',
          label: 'Fatura Bilgisi',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'kayitTarih',
          label: 'Düzenlenme Tarihi',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'toplamTutar',
          label: 'Fatura Tutarı',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
      ],
      // açık faturalar
      afPerPage: 10,
      afToplamKayit: 0,
      afMevcutKayit: 0,
      afFilter: '',
      afCurrentPage: 1,
      afFilterOn: [],
      // tahsilatlar
      tPerPage: 10,
      tToplamKayit: 0,
      tMevcutKayit: 0,
      tFilter: '',
      tCurrentPage: 1,
      tFilterOn: [],
      tFields: [
        {
          key: 'hareket',
          label: 'İşlem Türü',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'islemNotu',
          label: 'Açıklama',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'kayitTarih',
          label: 'Düzenlenme Tarihi',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'tutar',
          label: 'Tutar',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
      ],
    }
  },
  computed: {
    groupGet() {
      return _.orderBy(Object.values(store.getters.CARI_GROUP_GET), 'toplamCari', 'desc')
    },
    odemeKayitlari() {
      return store.getters.TAHSILAT_VE_ODEME_KAYITLARI ? _.orderBy(store.getters.TAHSILAT_VE_ODEME_KAYITLARI.data, 'kayitTarih', 'desc') : false
    },
    acikFaturalar() {
      return store.getters.ACIK_FATURALAR_GET ? _.orderBy(store.getters.ACIK_FATURALAR_GET, 'kayitTarih', 'desc') : false
    },
    faturalar() {
      return store.getters.FATURALAR_GET ? _.orderBy(store.getters.FATURALAR_GET, 'kayitTarih', 'desc') : false
    },
    hesapOzeti() {
      return store.getters.HESAP_OZETI_GET ? _.orderBy(store.getters.HESAP_OZETI_GET.data, 'kayitTarih', 'desc') : false
    },
    cariData() {
      return store.getters.CARI_DETAY_GET ? store.getters.CARI_DETAY_GET.cariData : false
    },
    cariOzet() {
      return store.getters.CARI_DETAY_GET ? store.getters.CARI_DETAY_GET.cariOzet : false
    },
    cariEkstre() {
      return store.getters.CARI_DETAY_GET.cariEkstre.ekstre ? _.orderBy(store.getters.CARI_DETAY_GET.cariEkstre.ekstre, 'kayitTarih', 'desc') : false
    },
  },
  watch: {
    tFilter(newVal) {
      this.$refs['cari-tab'].showLoading = true
      this.$store
          .dispatch('tahsilatlar', {
            uzunluk: this.tPerPage,
            baslangic: 0,
            searchKey: newVal,
            cariKartID: this.id,
          })
          .then(res => {
            this.$refs['cari-tab'].showLoading = false
            this.tToplamKayit = res.data.hesapOzeti.toplamKayit
            this.tMevcutKayit = res.data.hesapOzeti.data.length
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    afFilter(newVal) {
      this.$refs['cari-tab'].showLoading = true
      this.$store
          .dispatch('cariDetayFaturalar', {
            uzunluk: this.afPerPage,
            baslangic: 0,
            searchKey: newVal,
            cariKartID: this.id,
            acik: this.isAcikFatura,
          })
          .then(res => {
            this.$refs['cari-tab'].showLoading = false
            this.afToplamKayit = res.toplamKayit
            this.afMevcutKayit = res.data.length
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    fFilter(newVal) {
      this.$refs['cari-tab'].showLoading = true
      this.$store
          .dispatch('cariDetayFaturalar', {
            uzunluk: this.fPerPage,
            baslangic: 0,
            searchKey: newVal,
            cariKartID: this.id,
          })
          .then(res => {
            this.$refs['cari-tab'].showLoading = false
            this.fToplamKayit = res.toplamKayit
            this.fMevcutKayit = res.data.length
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    igFilter(newVal) {
      this.$refs['cari-tab'].showLoading = true
      this.$store
          .dispatch('cariDetayIslemGecmisi', {
            uzunluk: this.igPerPage,
            baslangic: 0,
            searchKey: newVal,
            cariKartID: this.id,
          })
          .then(res => {
            this.$refs['cari-tab'].showLoading = false
            this.igToplamKayit = res.toplamKayit
            this.igMevcutKayit = res.data.length
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    editCari: {
      deep: true,
      handler(newVal) {
        // SEHIR_ATAMA
        if (newVal.il) {
          this.ilceler = this.json.find(x => x.il_adi === newVal.il).ilceler
        }
      },
    },
  },
  methods: {
    ibanPaste(event) {
      const veri = event.clipboardData.getData('text')
      if (veri.includes('TR')) {
        this.addCari.ibann = veri.slice(2, 32)
      } else {
        this.addCari.ibann = veri.slice(0, 40)
      }
    },
    cariToYeniSatis() {
      store.state.cariToYeniSatisCariID = this.id
      this.$router.push({ name: 'yeniSatis' })
    },
    cariToYeniAlis() {
      store.state.cariToYeniAlisCariID = this.id
      this.$router.push({ name: 'yeniAlis' })
    },
    cariToYeniProforma() {
      store.state.cariToYeniProformaCariID = this.id
      this.$router.push({ name: 'yeniProforma' })
    },
    goFatura(item) {
      router.push({
        name: 'faturaDetay',
        params: { id: item.kayitID },
      })
    },
    cariEkstrePush() {
      router.push({
        name: 'cariEkstre',
        id: this.id,
      })
    },
    tahsilatlar() {
      this.$refs['cari-tab'].showLoading = true
      this.$store
          .dispatch('tahsilatlar', {
            uzunluk: this.tPerPage,
            baslangic: 0,
            searchKey: this.tFilter,
            cariKartID: this.id,
          })
          .then(res => {
            this.$refs['cari-tab'].showLoading = false
            this.tToplamKayit = res.data.hesapOzeti.toplamKayit
            this.tMevcutKayit = res.data.hesapOzeti.data.length
          })
    },
    fatura(acik = false) {
      this.isAcikFatura = acik
      this.$refs['cari-tab'].showLoading = true
      this.$store
          .dispatch('cariDetayFaturalar', {
            uzunluk: this.igPerPage,
            baslangic: 0,
            searchKey: acik ? this.fFilter : this.afFilter,
            cariKartID: this.id,
            acik,
          })
          .then(res => {
            this.$refs['cari-tab'].showLoading = false
            if (!acik) {
              this.fToplamKayit = res.toplamKayit
              this.fMevcutKayit = res.data.length
            } else {
              this.afToplamKayit = res.toplamKayit
              this.afMevcutKayit = res.data.length
            }
          })
    },
    devirGirisi() {
      this.devir.tutar = this.devir.tutar.replace(',', '.')
      store.dispatch('devirKayit', {
        aciklama: this.devir.islemNotu,
        tutar: this.devir.tutar,
        girisTuru: this.devir.hareket.key,
        id: this.id,
      })
          .then((res, position = 'bottom-right') => {
            this.$bvModal.hide('devir-girisi')
            if (res.result.status == 200) {
              this.$store
                  .dispatch('cariDetay', {
                    cariKartID: this.id,
                  })
                  .then((res, position = 'bottom-right') => {
                    this.igToplamKayit = res.data.cariEkstre.toplamKayit
                    this.cariNote = res.data.cariData.cariNot
                    const mesaj = this.$store.getters.notificationSettings(res)
                    if (mesaj) {
                      this.$toast(
                          {
                            component: ToastificationContent,
                            props: {
                              title: 'Başarılı',
                              text: mesaj,
                              icon: 'ThumbsUpIcon',
                              variant: 'success',
                            },
                          },
                          { position },
                      )
                    }
                  })
                  .catch(err => {
                    const mesaj = this.$store.getters.notificationSettings(err)
                    if (mesaj) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Hata!',
                          text: mesaj,
                          icon: 'ThumbsDownIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
            this.devirReset()
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    kasaGirisi(yontem, ref, kasaYontem = 2) {
      this.$refs[ref].showLoading = true
      this.sTahsilat.tutar = this.sTahsilat.tutar.replace(',', '.')
      this.cekTahsilat.tutar = this.cekTahsilat.tutar.replace(',', '.')
      const veri = this.igCurrentPage * this.igPerPage - this.igPerPage
      if (yontem == 'standart') {
        store.dispatch('kasaKayit', {
          aciklama: kasaYontem == 1 ? this.sTahsilat.islemNotu : this.sOdeme.islemNotu,
          tutar: kasaYontem == 1 ? this.sTahsilat.tutar : this.sOdeme.tutar,
          fisNo: kasaYontem == 1 ? this.sTahsilat.fisNo : this.sOdeme.fisNo,
          girisTuru: kasaYontem == 1 ? this.sTahsilat.tahsilat.key : this.sOdeme.tahsilat.key,
          id: this.id,
          uzunluk: this.igPerPage,
          baslangic: veri,
          searchKey: this.igFilter,
          yontem: kasaYontem,
        })
            .then((res, position = 'bottom-right') => {
              this.$bvModal.hide('tahsilat-girisi')
              this.$bvModal.hide('borc-girisi')
              this.igToplamKayit = res.data.cariEkstre.toplamKayit
              this.cariNote = res.data.cariData.cariNot
              this.$refs[ref].showLoading = false
              if (res.result.status == 200) {
                const mesaj = store.getters.notificationSettings(res)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı!',
                      text: mesaj,
                      icon: 'ThumbsDownIcon',
                      variant: 'success',
                    },
                  }, { position })
                }
              } else {
                const mesaj = store.getters.notificationSettings(res)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Hata!',
                      text: mesaj,
                      icon: 'ThumbsDownIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
              this.tahsilatReset()
            })
            .catch(err => {
              this.$refs[ref].showLoading = false
              const mesaj = store.getters.notificationSettings(err)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            })
      }
      if (yontem == 'cek') {
        this.$refs[ref].showLoading = false
      }
    },
    odemeReset() {
      this.cekOdeme.islemNotu = ''
      this.cekOdeme.tutar = ''
      this.cekOdeme.seriNo = ''
      this.cekOdeme.tahsilatTarihi = ''
      this.cekOdeme.isim = ''
      this.cekDuzenlenmeDate()
      this.$bvModal.hide('tahsilat-girisi')
      this.sOdeme.islemNotu = ''
      this.sOdeme.fisNo = ''
      this.sOdeme.tutar = ''
      this.sOdeme.tahsilat = {
        title: 'Nakit',
        key: 1,
      }
    },
    tahsilatReset() {
      this.cekTahsilat.islemNotu = ''
      this.cekTahsilat.tutar = ''
      this.cekTahsilat.seriNo = ''
      this.cekTahsilat.tahsilatTarihi = ''
      this.cekTahsilat.isim = ''
      this.cekDuzenlenmeDate()
      this.$bvModal.hide('tahsilat-girisi')
      this.sTahsilat.islemNotu = ''
      this.sTahsilat.fisNo = ''
      this.sTahsilat.tutar = ''
      this.sTahsilat.tahsilat = {
        title: 'Nakit',
        key: 1,
      }
    },
    devirReset() {
      this.devir.islemNotu = ''
      this.devir.tutar = ''
      this.devir.hareket = {
        title: 'Cari Borcu',
        key: 1,
      }
      this.$bvModal.hide('devir-girisi')
    },
    noteUpdate() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken)
      fd.append('serviceName', 'cari')
      fd.append('methodName', 'cariDetay')
      fd.append('islem', 'cariDuzenle')
      fd.append('cariKartID', this.id)
      fd.append('updateList[cariNot]', this.cariNote)
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            this.cariNote = res.data.data.cariData.cariNot
            store.commit('CARI_DETAY_ACTION', res.data)
            store.commit('HESAP_OZETI_ACTION', res.data.data.cariEkstre)
            store.commit('')
            this.$bvModal.hide('cari-edit')
            store.commit('setToken', res.data.userToken)
            store.commit('CARI_CARD_ACTION', res.data)
            store.commit('CARI_DETAY_ACTION', res.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: JSON.stringify(res.data.result?.errMsg)
                    .slice(1, -1),
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
            store.dispatch('cariCardlar', {
              searchKey: this.filter,
              uzunluk: this.perPage,
              baslangic: 0,
            })
                // eslint-disable-next-line no-shadow
                .then(res => {
                  this.totalRows = res.toplamKayit
                })
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    igPageChange(newVal) {
      const veri = newVal * this.igPerPage - this.igPerPage
      this.$refs['cari-tab'].showLoading = true
      this.$store
          .dispatch('cariDetayIslemGecmisi', {
            uzunluk: this.igPerPage,
            baslangic: veri,
            searchKey: this.igFilter,
            cariKartID: this.id,
          })
          .then(res => {
            this.$refs['cari-tab'].showLoading = false
            this.igToplamKayit = res.toplamKayit
            this.igMevcutKayit = res.data.length
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    tPageChange(newVal) {
      const veri = newVal * this.tPerPage - this.tPerPage
      this.$refs['cari-tab'].showLoading = true
      this.$store
          .dispatch('tahsilatlar', {
            uzunluk: this.tPerPage,
            baslangic: veri,
            searchKey: this.tFilter,
            cariKartID: this.id,
          })
          .then(res => {
            this.$refs['cari-tab'].showLoading = false
            this.tToplamKayit = res.data.hesapOzeti.toplamKayit
            this.tMevcutKayit = res.data.hesapOzeti.data.length
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    afPageChange(newVal) {
      const veri = newVal * this.afPerPage - this.afPerPage
      this.$refs['cari-tab'].showLoading = true
      this.$store
          .dispatch('cariDetayFaturalar', {
            uzunluk: this.afPerPage,
            baslangic: veri,
            searchKey: this.afFilter,
            cariKartID: this.id,
            acik: this.isAcikFatura,
          })
          .then(res => {
            this.$refs['cari-tab'].showLoading = false
            this.afToplamKayit = res.toplamKayit
            this.afMevcutKayit = res.data.length
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    fPageChange(newVal) {
      const veri = newVal * this.fPerPage - this.fPerPage
      this.$refs['cari-tab'].showLoading = true
      this.$store
          .dispatch('cariDetayFaturalar', {
            uzunluk: this.fPerPage,
            baslangic: veri,
            searchKey: this.fFilter,
            cariKartID: this.id,
          })
          .then(res => {
            this.$refs['cari-tab'].showLoading = false
            this.fToplamKayit = res.toplamKayit
            this.fMevcutKayit = res.data.length
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    tPerPageChange(newVal) {
      const veri = this.tCurrentPage * newVal - newVal
      this.$refs['cari-tab'].showLoading = true
      this.$store
          .dispatch('tahsilatlar', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.tFilter,
            cariKartID: this.id,
          })
          .then(res => {
            this.$refs['cari-tab'].showLoading = false
            this.tToplamKayit = res.data.hesapOzeti.toplamKayit
            this.tMevcutKayit = res.data.hesapOzeti.data.length
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    igPerPageChange(newVal) {
      const veri = this.igCurrentPage * newVal - newVal
      this.$refs['cari-tab'].showLoading = true
      this.$store
          .dispatch('cariDetayIslemGecmisi', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.igFilter,
            cariKartID: this.id,
          })
          .then(res => {
            this.$refs['cari-tab'].showLoading = false
            this.igToplamKayit = res.toplamKayit
            this.igMevcutKayit = res.data.length
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    fPerPageChange(newVal) {
      const veri = this.fCurrentPage * newVal - newVal
      this.$refs['cari-tab'].showLoading = true
      this.$store
          .dispatch('cariDetayFaturalar', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.fFilter,
            cariKartID: this.id,
          })
          .then(res => {
            this.$refs['cari-tab'].showLoading = false
            this.igToplamKayit = res.toplamKayit
            this.igMevcutKayit = res.data.length
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    afPerPageChange(newVal) {
      const veri = this.afCurrentPage * newVal - newVal
      this.$refs['cari-tab'].showLoading = true
      this.$store
          .dispatch('cariDetayFaturalar', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.afFilter,
            cariKartID: this.id,
            acik: this.isAcikFatura,
          })
          .then(res => {
            this.$refs['cari-tab'].showLoading = false
            this.afToplamKayit = res.toplamKayit
            this.afMevcutKayit = res.data.length
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    tahsilatDelete(kasaID) {
      this.$swal({
        title: 'Eminmisiniz?',
        text: 'Bu İşlem Geri Alınamaz',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet Sil!',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$store
                  .dispatch('deleteTahsilat', {
                    kasaID,
                    cariKartID: this.id,
                  })
                  .then((res, position = 'bottom-right') => {
                    if (res.result.status == 200) {
                      this.$store
                          .dispatch('cariDetay', {
                            cariKartID: this.id,
                          })
                      const mesaj = this.$store.getters.notificationSettings(res)
                      if (mesaj) {
                        this.$toast(
                            {
                              component: ToastificationContent,
                              props: {
                                title: 'Başarılı',
                                text: mesaj,
                                icon: 'ThumbsUpIcon',
                                variant: 'success',
                              },
                            },
                            { position },
                        )
                      }
                    } else {
                      const mesaj = this.$store.getters.notificationSettings(res)
                      if (mesaj) {
                        this.$toast(
                            {
                              component: ToastificationContent,
                              props: {
                                title: 'Hata!',
                                text: mesaj,
                                icon: 'ThumbsUpIcon',
                                variant: 'Warning',
                              },
                            },
                            { position },
                        )
                      }
                    }
                  })
                  .catch(err => {
                    const mesaj = this.$store.getters.notificationSettings(err)
                    if (mesaj) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Hata!',
                          text: mesaj,
                          icon: 'ThumbsDownIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
            }
          })
    },
    cariUpdate() {
      this.$refs['cari-info'].showLoading = true
      var guncellenenKeyler = {}
      Object.entries(this.degismeyenData)
          .forEach(entry => {
            const [key, value] = entry
            if (this.editCari[key] && this.editCari[key] != value) {
              guncellenenKeyler[key] = this.editCari[key]
            }
          })
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken)
      fd.append('serviceName', 'cari')
      fd.append('methodName', 'cariDetay')
      fd.append('islem', 'cariDuzenle')
      fd.append('cariKartID', this.editCari.cariCardID)
      fd.append('cariGrupID', this.cariData.cariGrupID)
      Object.entries(guncellenenKeyler)
          .forEach(entry => {
            const [key, value] = entry
            fd.append(`updateList[${key}]`, value || ' ')
          })
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            this.cariNote = res.data.data.cariData.cariNot
            store.commit('CARI_DETAY_ACTION', res.data)
            store.commit('HESAP_OZETI_ACTION', res.data.data.cariEkstre)
            this.$refs['cari-info'].showLoading = false
            if (res.data.result.status == 200) {
              this.$refs['cari-info'].showLoading = false
              this.$bvModal.hide('cari-edit')
              this.$store
                  .dispatch('cariDetay', {
                    cariKartID: this.id,
                  })
                  .then((res, position = 'bottom-right') => {
                    const mesaj = this.$store.getters.notificationSettings(res)
                    if (mesaj) {
                      this.$toast(
                          {
                            component: ToastificationContent,
                            props: {
                              title: 'Başarılı',
                              text: mesaj,
                              icon: 'ThumbsUpIcon',
                              variant: 'success',
                            },
                          },
                          { position },
                      )
                    }
                  })
                  .catch(err => {
                    const mesaj = this.$store.getters.notificationSettings(err)
                    if (mesaj) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Hata!',
                          text: mesaj,
                          icon: 'ThumbsDownIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
              store.commit('setToken', res.data.userToken)
              store.commit('CARI_DETAY_ACTION', res.data)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: JSON.stringify(res.data.result?.errMsg)
                      .slice(1, -1),
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            } else {
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            this.$refs['cari-info'].showLoading = false
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    cariEdit() {
      if (this.cariData.il) {
        this.ilceler = this.json.find(x => x.il_adi === this.cariData.il).ilceler
      }
      this.editCari.cariGrupID = this.cariData.cariGrupID
      this.editCari.cariCardID = this.cariData.cariKartID
      this.editCari.adi = this.cariData.adi
      this.editCari.soyAdi = this.cariData.soyAdi
      this.editCari.adres = this.cariData.adres
      this.editCari.ePosta = this.cariData.ePosta
      this.editCari.telefon = this.cariData.telefon
      this.editCari.firmaUnvan = this.cariData.firmaUnvan
      this.editCari.vergiNumarasi = this.cariData.vergiNumarasi
      this.editCari.vergiDairesi = this.cariData.vergiDairesi
      this.editCari.bakiye = this.cariData.bakiye
      this.editCari.il = this.cariData.il
      this.editCari.ilce = this.cariData.ilce
      this.editCari.cariNot = this.cariData.cariNot
      this.editCari.banka = this.cariData.banka
      this.editCari.ibann = this.cariData.ibann
      this.degismeyenData = this.cariData
    },
    editHide() {
      const t = this
      t.editCari.cariCardID = ''
      t.editCari.adi = ''
      t.editCari.soyAdi = ''
      t.editCari.adres = ''
      t.editCari.ePosta = ''
      t.editCari.telefon = ''
      t.editCari.firmaUnvan = ''
      t.editCari.vergiNumarasi = ''
      t.editCari.vergiDairesi = ''
      t.editCari.il = ''
      t.editCari.ilce = ''
      t.editCari.bakiye = ''
      t.editCari.cariNot = ''
      t.editCari.banka = ''
      t.editCari.ibann = ''
    },
    cariRemove() {
      store.dispatch('cariCardRemove', this.id)
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.$router.push({ name: 'cariListe' })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: res.result?.errMsg,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            } else if (res.result.status == 303) {
              this.$swal({
                title: res.result?.errMsg,
                text: res.result?.helpMsg,
                icon: 'warning',
                confirmButtonText: 'Tamam',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                showCloseButton: true,
                buttonsStyling: false,
              })
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    handleResize() {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 3000)
    },
    cekDuzenlenmeDate() {
      var today = new Date()
      var dd = String(today.getDate())
          .padStart(2, '0')
      var mm = String(today.getMonth() + 1)
          .padStart(2, '0')
      var yyyy = today.getFullYear()
      this.cekTahsilat.duzenlenmeTarihi = yyyy + '-' + mm + '-' + dd
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth
    this.windowHeight = window.innerHeight
    window.addEventListener('resize', this.handleResize)
  },
  created() {
    this.cekDuzenlenmeDate()
    store.dispatch('cariGrup')
        .then(res => {
          this.grupList = res.cariGrupListesi
        })
    this.$store
        .dispatch('cariDetay', {
          cariKartID: this.id,
        })
        .then((res, position = 'bottom-right') => {
          this.igToplamKayit = res.data.cariEkstre.toplamKayit
          this.cariNote = res.data.cariData.cariNot
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('CARI_DETAY_ACTION', 'remove')
    this.$store.commit('HESAP_OZETI_ACTION', 'remove')
    this.$store.commit('ISLEM_GECMISI_ACTION', 'remove')
    this.$store.commit('FATURALAR_ACTION', 'remove')
    this.$store.commit('TAHSILAT_VE_ODEME_KAYITLARI_ACTION', 'remove')
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>
<style scoped>
@media screen and (max-width: 1550px) {
  .media-responsive {
    flex: 0 0 auto;
    width: 50% !important;
  }
}

@media screen and (min-width: 1550px) {
  .media-responsive {
    flex: 0 0 auto;
    width: 33.3333333333%
  }
}

@media screen and (max-width: 1200px) {
  .media-responsive {
    flex: 0 0 auto;
    width: 33.3333333333% !important;
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
.islem-gecmisi .table th, .table td {
  padding: 0.20% !important;
}

.table th, .table td {
  padding: 0.10% !important;
}

#cari-info .card-header {
  display: none !important;
}

#cari-detay-page #cari-info-card .card {
  margin-bottom: 2% !important;
}

#cari-detay-page .card .card-header .heading-elements {
  display: none !important;
}

#cari-detay-page .cari-avatar {
  width: 94px !important;
  height: 94px !important;
}

#cari-detay-page .cari-adres {
  font-size: 11px;
}

#cari-tab .card-header {
  display: none !important;
}

.customer-icon {
  height: 100px;
  width: 100px;
}
</style>
<style scoped>

.media-aside-p {
  margin-right: 0.4rem !important;
}

.cari-ozet-money {
  font-size: 16px;
}

.tahsilat-ve-odeme-kayitlari .table th, .table td {
  padding: 0.1rem;
}

.search-bar {
  width: 20% !important;
}

.search-input {
  border-color: #c3baba !important;
}

#filterInput:focus {
  border-color: #c3baba !important;
}

@media screen and (max-width: 1440px) {
  #cari-detay-page {
    /*display: none !important;*/
  }
}

#time-line-hr {
  height: 2px;
  width: 90%;
  position: absolute;
  margin-top: 1.9%;
}

.pull-up {
  transition: 200ms;
}

#cari-info .card-title {
  background-color: #e5f8ed;
  border-radius: 8px;
  color: #17c565;
  padding: 4px;
  font-size: 15px;
}

/*.co-xl-4, .col-12 {*/
/*  padding-right: 0 !important;*/
/*  padding-left: 0 !important;*/
/*}*/

#cari-info #card-btn {
  justify-content: space-between !important;
  display: flex !important;
}

.media-body p {
  white-space: nowrap !important;
}
</style>
